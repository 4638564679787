.footer {
  background-color: #062239; /* Deep blue background */
  color: white;
  text-align: center;
  padding: 20px 10px;
}

.footer-logo {
  margin-bottom: 15px;
}

.footer-logo img {
  width: 50px; /* Adjust the size of the logo */
}

.footer-links {
  list-style: none;
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 0;
  margin: 10px 0;
}

.footer-links li {
  cursor: pointer;
  font-size: 0.9rem;
}

.footer-links li:hover {
  text-decoration: underline;
}

.footer-email {
  margin: 10px 0;
  font-size: 0.9rem;
}

.footer-email a {
  color: #ffd700; /* Highlight email in gold */
  text-decoration: none;
}

.footer-email a:hover {
  text-decoration: underline;
}

.social-media {
  margin-top: 10px;
}

.instagram-link {
  font-size: 1.5rem;
  color: #ffd700; /* Gold color for Instagram icon */
  transition: transform 0.3s;
}

.instagram-link:hover {
  transform: scale(1.2); /* Slightly enlarge on hover */
}

.footer-phone {
  color: #ffffff;
  margin-top: 10px;
  font-size: 0.9rem;
}

.footer-phone a {
  color: #ffd700; 
  text-decoration: none;
}

.footer-phone a:hover {
  text-decoration: underline;
}
