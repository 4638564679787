/* Hero Section Styling */
.hero-section {
  position: relative;
  height: 100vh; /* Full viewport height */
  color: white;
  text-align: center;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hero-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3); /* Dark overlay for better text visibility */
  z-index: -1;
}


.hamburger-menu {
  display: none; /* Hidden by default */
  background: none;
  border: none;
  font-size: 1.5rem;
  color: white;
  cursor: pointer;
  outline: none;
  position: absolute;
  top: 15px;
  left: 10px; /* Move hamburger to the left corner */
  z-index: 15;
  transition: transform 0.3s ease;
}

.hamburger-menu.open {
  color: #ffd700; /* Change color when open */
}

.media-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: -2;
}

.hero-video {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the video fills the container */
  display: block; /* Default display for large screens */
}

.hero-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: none; /* Hidden by default */
}

.hero-content {
  position: relative;
  z-index: 5;
  text-align: center;
}

.hero-content h1 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.hero-content p {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.enquire-now-btn {
  background: #ffd700;
  color: #000;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: transform 0.3s ease, background 0.3s ease;
}

.enquire-now-btn:hover {
  background: #ffcc00;
  transform: scale(1.1);
}

.mobile-logo {
  display: none; /* Hidden by default */
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero-video {
    display: none; /* Hide video on smaller screens */
  }

  .hero-image {
    display: block; /* Show image for mobile */
  }

  .logo {
    display: none; /* Hide the main logo on mobile */
  }

  .mobile-logo {
    display: block; /* Show mobile logo */
    width: 120px; /* Larger logo for mobile screens */
    margin: 40px auto 10px; /* Add more space above, less below */
  }

  .hamburger-menu {
    display: block; /* Visible for smaller screens */
    font-size: 2rem;
    left: 5px; /* Ensure hamburger stays in the left corner */
  }

  .hero-content {
    margin-top: 2vh; /* Adjust margin for spacing */
  }

  .hero-content h1 {
    font-size: 2rem;
  }

  .hero-content p {
    font-size: 1rem;
  }

  .enquire-now-btn {
    font-size: 1rem;
    padding: 8px 15px;
  }
}


