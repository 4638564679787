.testimonials {
  background-color: #faf3e7;
  padding: 50px 20px;
  text-align: center;
}

.testimonials-title {
  font-size: 2.5rem;
  color: #062239;
  margin-bottom: 30px;
  font-family: "Poppins", sans-serif;
}

/* Slider Container */
.slider-container {
  position: relative;
  margin: 0 auto;
  overflow: hidden;
}

.slides {
  display: flex;
  transition: transform 0.5s ease-in-out;

}

.slide {
  flex: 1 0 100%; /* Show 1 slide at a time */
  max-width: 100%;
  box-sizing: border-box;
}

.testimonial-card {
  background: white;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  width: 90%;
  margin: auto;
  position: relative;
  overflow: hidden;
}

.testimonial-card::before {
  content: "";
  display: block;
  width: 100%;
  height: 10px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--card-border-color, #062239); /* Dynamic color */
}

.testimonial-card img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 15px;
}

.testimonial-card h4 {
  font-size: 1.4rem;
  margin-bottom: 10px;
  color: #333;
  font-family: "Poppins", sans-serif;
}

.testimonial-card p {
  font-size: 1rem;
  color: #555;
  line-height: 1.5;
  font-family: "Roboto", sans-serif;
}

/* Navigation Buttons */
.prev-btn,
.next-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.6);
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 10;
}

.prev-btn {
  left: 10px;
}

.next-btn {
  right: 10px;
}

.prev-btn:hover,
.next-btn:hover {
  background: rgba(0, 0, 0, 0.8);
}

/* Mobile and Tablet */
@media (max-width: 768px) {

  .prev-btn,
.next-btn {

  width: 50px;
  height: 50px;
}

  /* Slider Container */
.slider-container {
  max-width: 95%;
}


.slides {
  gap: 2px; /* Add spacing between slides */
}

  .testimonial-card {
    padding: 20px;
    width: 80%;
  }

  .testimonial-card h4 {
    font-size: 1.2rem;
  }

  .testimonial-card p {
    font-size: 0.9rem;
  }

  .prev-btn,
  .next-btn {
    width: 30px;
    height: 30px;
    font-size: 1.2rem;
  }
}

/* For Desktop (Laptop and larger screens) */
@media (min-width: 1024px) {
  .slides {
    display: flex;
    gap: 20px; /* Add some spacing between slides */
  }

  .slide {
    flex: 1 0 calc(33.33% - 20px); /* Show 3 slides at a time with spacing */
    max-width: calc(33.33% - 20px);
  }
  
  .testimonial-card {
    height: 45vh;
  }
  
}

