
  .logo {
    width: 80px;
    cursor: pointer;
  }
  
  
  /* Packages Section */
.packages {
    background-color: #faf3e7;
    text-align: center;
    padding-top: 25vh;
    padding-left: 2vh;
    padding-right: 2vh;
  }
  
  .packages-title {
    font-size: 2.5rem;
    color: #062239;
    margin-bottom: 30px;
    font-family: "Poppins", sans-serif;
  }
  
  /* Place Group */
  .place-group {
    margin-bottom: 40px;
  }
  
  .place-title {
    font-size: 2rem;
    color: #062239;
    margin-bottom: 20px;
  }
  
  /* Packages Grid */
  .packages-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    justify-content: center;
    align-items: stretch;
  }
  
  /* Package Card */
  .package-card {
    background: white;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .package-card:hover {
    transform: translateY(-5px);
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
  }
  
  .package-image {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
  
  .package-content {
    padding: 20px;
    text-align: left;
  }
  
  .package-title {
    font-size: 1.4rem;
    color: #062239;
    margin-bottom: 10px;
  }
  
  .package-description {
    font-size: 1rem;
    color: #555;
    margin-bottom: 15px;
  }
  
  .package-price {
    font-size: 1.2rem;
    color: #ff5722;
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  .book-now-btn {
    background: #ffd700;
    color: #000;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .book-now-btn:hover {
    background: #ffcc00;
  }
  

  .packages-title
  {
      margin-top: 0;
  }

  @media (max-width: 768px) {
    /* Packages Section */
.packages {
    padding-top: 5vh;

  }

    .packages-hamburger-menu {
      display: block;
      font-size: 3rem;
      color: #062239;
      position: absolute; /* Ensures the position is relative to the navbar */
      right: -30px; /* Moves it closer to the right corner */
      top: 10px; /* Aligns it with the navbar vertically */
    }
  
    .mobile-logo {
      display: block; /* Show mobile logo */
      width: 120px; /* Larger logo for mobile screens */
      margin: 40px auto 10px; /* Add more space above, less below */
    }
  
    .packages_navbar {
      background-color: rgba(0, 0, 0, 0);
    }
  
    .nav-links {
      display: none;
      flex-direction: column;
      margin-right: 0;
    }
  
    .nav-links.active {
      display: flex;
    }

    .packages-title
    {
        margin-top: 10rem;
    }
  }

  a{
    text-decoration: none;
  }
  
  
