/* General Section Styling */
.enquiry-form {
    background-color: #faf3e7;
    padding: 60px 20px; /* Added more padding for better spacing */
    text-align: center;
  }
  
  .form-container {
    max-width: 400px; /* Smaller form size */
    margin: 0 auto;
    background: white;
    padding: 20px 15px; /* Compact padding inside the form */
    border-radius: 10px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    position: relative;
    padding-left: 1.5rem;
  }
  
  .form-container::before {
    content: "";
    position: absolute;
    top: -50px;
    right: -50px;
    width: 150px;
    height: 150px;
    background: rgba(0, 123, 255, 0.1);
    border-radius: 50%;
  }
  
  .form-container h2 {
    font-size: 2.5rem;
    color: #062239;
    margin-bottom: 10px;
  }
  
  .form-container .form-description {
    font-size: 1rem;
    color: #555;
    margin-bottom: 30px;
  }
  
  .form-group {
    margin-bottom: 20px;
    text-align: left;
  }
  
  .form-group label {
    font-weight: bold;
    font-size: 1rem;
    display: block;
    margin-bottom: 8px;
    color: #333;
  }
  
  .form-group input,
  .form-group textarea {
    width: 90%;
    padding: 15px; /* Increased padding for better spacing */
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 1rem;
    outline: none;
    transition: box-shadow 0.3s ease-in-out, border-color 0.3s ease-in-out;
  }
  
  .form-group input:focus,
  .form-group textarea:focus {
    border-color: #062239;
    box-shadow: 0 4px 10px rgba(0, 123, 255, 0.1);
  }
  
  .form-group textarea {
    resize: none;
  }
  
  .submit-btn {
    background: #062239;
    color: white;
    padding: 15px 30px; /* Increased button size for better clickability */
    border: none;
    border-radius: 8px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background 0.3s ease-in-out, transform 0.2s ease-in-out;
  }
  
  .submit-btn:hover {
    background: #0056b3;
    transform: translateY(-3px);
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .form-container {
      padding: 30px 20px; /* Adjusted padding for smaller screens */
    }
  
    .form-container h2 {
      font-size: 2rem;
    }
  
    .form-container .form-description {
      font-size: 0.9rem;
    }
  
    .submit-btn {
      padding: 12px 25px; /* Adjusted button size for smaller screens */
      font-size: 1rem;
    }
  }
  
  .enquiry-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .form-container {
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    max-width: 500px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
    position: relative;
  }
  
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .success-message {
    background: #d4edda;
    color: #155724;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
  }
  
  .error-message {
    background: #f8d7da;
    color: #721c24;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  .submit-btn {
    background: #ffd700;
    color: #000;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .submit-btn:hover {
    background: #ffcc00;
  }
  
  /* Success Message Styling */
.success-message {
  background: rgba(0, 255, 0, 0.1); /* Subtle green background */
  color: #28a745; /* Green text */
  padding: 15px 20px; /* Spacious padding for better readability */
  margin: 20px auto; /* Centered with space from other elements */
  border-radius: 10px; /* Rounded corners to match form styling */
  max-width: 400px; /* Matches the form container width */
  text-align: center; /* Center-align the text */
  font-size: 1.2rem; /* Slightly larger text for visibility */
  box-shadow: 0 4px 10px rgba(0, 255, 0, 0.2); /* Subtle shadow for prominence */
  border: 1px solid #28a745; /* Subtle green border for clarity */
}

/* Error Message Styling */
.error-message {
  background: rgba(255, 0, 0, 0.1); /* Subtle red background */
  color: #dc3545; /* Red text */
  padding: 15px 20px;
  margin: 20px auto;
  border-radius: 10px;
  max-width: 400px;
  text-align: center;
  font-size: 1.2rem;
  box-shadow: 0 4px 10px rgba(255, 0, 0, 0.2);
  border: 1px solid #dc3545;
}
