.whatsapp-floater {
    position: fixed;
    bottom: 20px;
    right: 20px; /* Changed from left to right */
    width: 60px;
    height: 60px;
    background-color: #25d366;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    z-index: 1000;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.whatsapp-floater:hover {
    transform: scale(1.1);
    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.2);
}

.whatsapp-icon {
    color: white;
    font-size: 2rem;
}
