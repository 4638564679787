/* General Navbar Styling */
.navbar2 {
    display: flex;
    justify-content: space-between; /* Space between logo and links */
    align-items: center;
    padding: 10px 40px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
    box-sizing: border-box;
    transition: background-color 0.3s ease;
    background-color: rgba(0, 0, 0, 0.8);
}
  
  /* Logo Styling */
  .navbar2-logo {
    width: 80px;
    cursor: pointer;
  }
  
  /* Hamburger Menu Styling */
  .navbar2-hamburger {
    display: none; /* Hidden on larger screens */
    font-size: 2rem;
    background: none;
    border: none;
    cursor: pointer;
    position: absolute; /* Use absolute positioning */
    right: 20px; /* Align to the right */
    top: 15px; /* Vertically align with the navbar */
    transition: color 0.3s ease;
  }
  
  /* Desktop Links Styling */
  .navbar2-links {
    list-style: none;
    display: flex;
    justify-content: flex-end; /* Align links to the right */
    gap: 30px;
    margin-right: 40px; 
  }
  
  .navbar2-links li a {
    color: white;
    text-decoration: none;
    font-size: 1rem;
    transition: color 0.3s ease;
  }
  
  .navbar2-links li a:hover {
    color: #ffd700;
  }
  
  /* Mobile Menu Styling */
  .navbar2-links.active {
    display: flex;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.9);
    position: absolute;
    top: 60px;
    right: 20px;
    padding: 20px;
    border-radius: 10px;
  }
  
  .navbar2-links.active li {
    margin-bottom: 10px;
  }
  
  .navbar2-links.active li a {
    font-size: 1.2rem;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .navbar2-hamburger {
    display: block; /* Show hamburger menu on smaller screens */
      font-size: 3rem;
      color: white;
      right: 0px;
    }

    .navbar2-links {
        display: none; /* Hide nav links on smaller screens */
        flex-direction: column;
        align-items: flex-start; /* Align links to the start in the dropdown */
        gap: 15px;
        margin-right: 0;
        display: flex;
      }
    

    .hamburger-menu {
        display: block; /* Show hamburger menu on smaller screens */
        font-size: 3rem;
        color: white;
        right: -350px; /* Align to the right */
      }
   
    
  
    .navbar2-links {
      display: none; /* Hide nav links on smaller screens */
      flex-direction: column;
      align-items: flex-start; /* Align links to the start in the dropdown */
      gap: 15px;
      margin-right: 0;
    }
  
    .navbar2-links.active {
      display: flex;
    }

    
    .navbar2-links {
    display: none; /* Hide nav links by default */
    flex-direction: column;
    gap: 10px;
    position: absolute;
    top: 60px;
    left: 20px;
    background: rgba(0, 0, 0, 0.8);
    padding: 15px;
    border-radius: 10px;
    z-index: 15;
  }

  .navbar2-links.active {
    display: flex; /* Show nav links when menu is toggled */
  }
  }
  