.navbar {
    display: flex;
    justify-content: space-between; /* Space between logo and links */
    align-items: center;
    padding: 10px 40px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
    box-sizing: border-box;
    transition: background-color 0.3s ease; /* Smooth background transition */
  }
  
  .navbar-bg {
    background-color: rgba(0, 0, 0, 0.8); /* Dark background for non-HeroSection */
  }
  
  .logo {
    width: 80px;
    cursor: pointer;
  }
  
  .hamburger-menu {
    display: none; /* Hidden on larger screens */
    font-size: 2rem;
    background: none;
    border: none;
    cursor: pointer;
    position: absolute; /* Use absolute positioning */
    right: 20px; /* Align to the right */
    top: 15px; /* Vertically align with the navbar */
    transition: color 0.3s ease;
  }
  
  .nav-links {
    list-style: none;
    display: flex;
    justify-content: flex-end; /* Align links to the right */
    gap: 30px;
    margin-right: 40px; /* Space from the right edge */
  }
  
  .nav-links li {
    color: white;
    font-size: 1rem;
  }
  
  .nav-links a {
    color: white;
    text-decoration: none;
  }
  
  .nav-links a:hover {
    color: #ffd700;
  }
  
  .nav-links.active {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: rgba(0, 0, 0, 0.9);
    position: absolute;
    top: 60px;
    right: 20px; /* Align dropdown to the right */
    padding: 20px;
    border-radius: 10px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .hamburger-menu {
      display: block; /* Show hamburger menu on smaller screens */
      font-size: 3rem;
      color: white;
      right: -350px; /* Align to the right */
    }
  
    .nav-links {
      display: none; /* Hide nav links on smaller screens */
      flex-direction: column;
      align-items: flex-start; /* Align links to the start in the dropdown */
      gap: 15px;
      margin-right: 0;
    }
  
    .nav-links.active {
      display: flex;
    }
  
    .navbar-bg {
      background-color: rgba(0, 0, 0, 0); /* Ensure proper background color */
    }

    
  .nav-links {
    display: none; /* Hide nav links by default */
    flex-direction: column;
    gap: 10px;
    position: absolute;
    top: 60px;
    left: 20px;
    background: rgba(0, 0, 0, 0.8);
    padding: 15px;
    border-radius: 10px;
    z-index: 15;
  }

  .nav-links.active {
    display: flex; /* Show nav links when menu is toggled */
  }
  }
  