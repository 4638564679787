.about-us {
    padding: 50px 20px;
    text-align: center;
    background: #faf3e7;
  }
  
  .about-us h2 {
    font-size: 2.5rem;
    margin-bottom: 10px;
  }
  
  .about-us p {
    font-size: 1.2rem;
    color: #666;
    margin: 0 auto;
    max-width: 600px;
  }
  