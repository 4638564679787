.featured-trips {
  padding: 50px 20px;
  text-align: center;
  background-color: #faf3e7;
}

.featured-trips h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.trip-cards {
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap; /* Enables wrapping for smaller screens */
}

.trip-card {
  border-radius: 10px;
  width: calc(25% - 20px); /* 4 cards per row, with gap */
  text-align: center;
  background-color: white;
  box-shadow: 0 4px 10px rgba(50, 29, 29, 0.1);

}


.trip-card img {
  width: 100%;
  height: 200px;
  border-radius: 10px 10px 0 0;
  object-fit: cover; /* Ensures the image scales properly */
}

.trip-card h3 {
  font-size: 1.5rem;
  margin: 10px 0;
}

.trip-card p {
  font-size: 1rem;
  color: #555;
  padding: 0 10px 10px;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .trip-card {
    width: calc(50% - 20px); /* 2 cards per row on tablets */
  }

  .trip-card h3 {
    font-size: 1.3rem;
  }

  .trip-card p {
    font-size: 0.9rem;
  }
}

@media (max-width: 768px) {
  .featured-trips h2 {
    font-size: 2rem; /* Adjust header size */
  }

  .trip-card {
    width: 100%; /* 1 card per row on mobile */
  }

  .trip-card img {
    height: 260px; /* Adjust image height */
  }

  .trip-card h3 {
    font-size: 1.2rem;
  }

  .trip-card p {
    font-size: 0.85rem;
  }
}

@media (max-width: 480px) {
  .featured-trips {
    padding: 30px 10px;
  }

  .featured-trips h2 {
    font-size: 1.8rem;
  }

  .trip-card img {
    height: 200px;
  }

  .trip-card h3 {
    font-size: 1rem;
  }

  .trip-card p {
    font-size: 0.8rem;
  }
}
