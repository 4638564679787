.package-details {
    text-align: center;
    margin-top: 6.5rem;
  }
  
  .details-image {
    width: 100%;
    max-height: 400px;
    object-fit: cover;
    margin-bottom: 20px;
  }
  
  .package-details h1 {
    font-size: 2.5rem;
    margin: 20px 0;
  }
  
  .package-details p {
    font-size: 1.2rem;
    margin-bottom: 20px;
    padding: 0px 15px;
  }
  
  .details-price {
    font-size: 1.5rem;
    color: #ff5722;
    font-weight: bold;
  }
  
  .enquire-now-btn {
    background-color: #25d366;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 5vh;
  }
  
  .enquire-now-btn:hover {
    background-color: #1db954;
  }
  
  /* Modal Overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation: fadeIn 0.3s ease;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  /* Modal Content */
  .modal {
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    max-width: 500px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
    position: relative;
    animation: slideIn 0.3s ease;
  }
  
  @keyframes slideIn {
    from {
      transform: translateY(-20px);
    }
    to {
      transform: translateY(0);
    }
  }
  
  .close-modal-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .modal h3 {
    margin-bottom: 20px;
    font-size: 1.5rem;
    color: #062239;
  }
  
  .modal-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group input,
  .form-group textarea {
    width: 96%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  .submit-btn {
    background: #ffd700;
    color: #000;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .submit-btn:hover {
    background: #ffcc00;
  }
  
  .success-message {
    color: #28a745;
    margin-bottom: 15px;
    font-weight: bold;
  }
  
  .error-message {
    color: #dc3545;
    margin-bottom: 15px;
    font-weight: bold;
  }
  